import { createEventId } from "../../_helpers";
import { sendLog } from "../../v2/businesslogics/logging";
import { getCookie } from "../../v2/_helper";

export async function logPaymentPageview(
  course_id,
  paymentInfoByCourseId,
  isGov
) {
  if (!paymentInfoByCourseId.loaded) {
    return;
  }

  const eventID1 = createEventId();
  const logTitle =
    course_id === "6115cea3e73913245c9538e0" // 내일배움단 합류하기 강의
      ? "nb_quizPage_view"
      : "scc_paymentPage_view";

  sendLog(
    logTitle,
    {
      type: isGov ? "국비지원" : "일반",
      course_id,
      ...paymentInfoByCourseId.data.context,
    },
    eventID1
  );

  window.dataLayer.push({
    event: "pageview",
    event_name: "view_scc_payment",
    page_title: document.title,
    page_path: window.location.pathname,
    page_url: window.location.href,
    ...paymentInfoByCourseId.data.context,
  });
}

export async function logCheckout(
  course_id,
  paymentInfoByCourseId,
  currency,
  finalPrice
) {
  if (!paymentInfoByCourseId.loaded) {
    return;
  }

  const { course, price, point, coupons } = paymentInfoByCourseId.data.context;
  const { title } = course;
  const eventID2 = createEventId();

  sendLog(
    "scc_initiate_checkout",
    {
      currency: currency,
      value: finalPrice,
      content_ids: [course_id],
      course_id,
    },
    eventID2
  );
  sendLog(
    "scc_paymentPage_startPay_click",
    {
      course_title: title,
      course_id: course_id,
      show_price: price.earlybird_price,
      display_price: price.display_price,
      coupon_title: coupons[0]?.title,
      coupon_price: coupons[0]?.benefit_detail,
      point: point,
      final_price: finalPrice,
      is_free: course?.is_trial,
      payment_method: window.payment_method,
    },
    eventID2
  );
  window.dataLayer.push({
    event: "click",
    event_name: "click_scc_start_payment",
    page_title: document.title,
    page_path: window.location.pathname,
    page_url: window.location.href,
    course_title: title,
  });
}

export async function logPurchaseUTM(order_no) {
  // 결제정보와 대응되는 UTM log저장
  let utm_source = getCookie("utm_source");
  let utm_medium = getCookie("utm_medium");
  let utm_campaign = getCookie("utm_campaign");
  let utm_content = getCookie("utm_content");
  let utm_term = getCookie("utm_term");
  let log_data = {
    order_no,
    utm_source,
    utm_medium,
    utm_campaign,
    utm_content,
    utm_term,
  };
  sendLog("", log_data, "", "purchase_utm");
}
