import React from "react";

import * as S from "./kdcFinalPrice.style";

export const KdcFinalPrice = ({ price }: { price: number }) => {
  return (
    <S.Container>
      <S.KdcFinalPrice>
        {price.toLocaleString()}
        <b>원</b>
      </S.KdcFinalPrice>
    </S.Container>
  );
};

const VerifiedIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.815 1.91315C10.4916 1.35446 9.79108 1.07522 9.15278 1.36506L7.99819 1.88923L6.85143 1.37585C6.22317 1.09189 5.51058 1.35702 5.18791 1.93079L4.53612 3.06712L3.30601 3.35682C2.655 3.50985 2.23144 4.12388 2.29228 4.78344L2.4109 6.11792L1.5638 7.13126L1.56249 7.13283C1.14584 7.634 1.14583 8.36618 1.56248 8.86735L2.41099 9.8776L2.2923 11.2224C2.23196 11.8697 2.64902 12.4933 3.29836 12.6488L4.53421 12.9434L5.18307 14.0825L5.18567 14.087C5.51194 14.6507 6.21194 14.9204 6.84816 14.6376L8.00311 14.1112L9.14706 14.6233L9.14931 14.6243C9.77828 14.9086 10.4918 14.6425 10.8139 14.0674L11.4616 12.9256L12.694 12.6318L12.6953 12.6315C13.3595 12.475 13.7624 11.8441 13.7028 11.2046L13.5842 9.86575L14.4231 8.86685C14.8671 8.3635 14.8457 7.62302 14.4382 7.13282L13.5898 6.12268L13.7084 4.78383C13.7688 4.13646 13.3517 3.51276 12.7023 3.3573L11.4673 3.06281L10.8183 1.91879L10.815 1.91315ZM10.5425 7.03109C10.7437 6.84197 10.7535 6.52554 10.5644 6.32432C10.3753 6.1231 10.0588 6.11329 9.85761 6.30241L7.22087 8.78058L6.20913 7.82968C6.00791 7.64056 5.69148 7.65038 5.50236 7.85159C5.31324 8.05281 5.32305 8.36924 5.52427 8.55836L6.87844 9.83109C7.07089 10.012 7.37084 10.012 7.5633 9.83109L10.5425 7.03109Z"
        fill="url(#paint0_linear_423_17945)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_423_17945"
          x1="1"
          y1="8"
          x2="15"
          y2="8"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#31B32E" />
          <stop offset="1" stop-color="#2EC4B6" />
        </linearGradient>
      </defs>
    </svg>
  );
};
