import { CheckLine } from "@teamsparta/design-system";
import { CenterPoint } from "../../../pages/RoadmapPage/CurrentlyEnrolled/CurrentlyEnrolled.svg";
import React, { useEffect, useState } from "react";
import * as S from "./ProgressEncourage.style";
import { useSelector } from "react-redux";
import {
  useGetDoneLectureNumber,
  useGetRtanImageByStep,
  useGetStepByProgressRate,
} from "../../../businesslogics/Roadmap/progressEncourage";

const stateItems = [
  {
    rateText: "진도율 20%",
    description: "새싹 르탄이 획득",
    step: 2,
  },
  {
    rateText: "진도율 50%",
    description: "랜덤 르탄이 획득",
    step: 3,
  },
  {
    rateText: "진도율 100%",
    description: "강의 평생 소장",
    step: 4,
  },
];

export const ProgressEncourage = ({ progressRate }) => {
  const step = useGetStepByProgressRate(progressRate);
  const rtanImageSrc = useGetRtanImageByStep(step);
  const { user } = useSelector((state) => state.authentication);
  const roundWeeks = useSelector((state) => state.roundWeeks);

  const { week, weekOrder } = useGetDoneLectureNumber(roundWeeks);

  return (
    <S.Container>
      <S.TopContainer>
        <S.RtanImage src={rtanImageSrc} />
      </S.TopContainer>
      <S.StateBoardWrap>
        <S.StateBoard>
          {stateItems.map((item, index) => (
            <React.Fragment key={index}>
              <S.StateItem>
                <S.StateItemTextContainer>
                  <img
                    src={`/assets/images/roadmap/rtans/step_${index + 1}${
                      step >= item.step ? "" : "_disabled"
                    }.svg`}
                    alt={item.rateText}
                  />
                  <S.StateItemRateText>{item.rateText}</S.StateItemRateText>
                  <CenterPoint />
                  <S.StateItemText step={item.step}>
                    {item.description}
                  </S.StateItemText>
                </S.StateItemTextContainer>
                {step >= item.step && <CheckLine size={16} color={"#E8344E"} />}
              </S.StateItem>
              {index < stateItems.length - 1 && <S.DivierRow />}
            </React.Fragment>
          ))}
        </S.StateBoard>
      </S.StateBoardWrap>
    </S.Container>
  );
};
