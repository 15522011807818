import { keyframes } from "@emotion/css";
import styled from "@emotion/styled";
import React, { useEffect } from "react";
import { logout, params } from "../../../_helpers"; // 로그아웃을 수행하는 빈 페이지

// 로그아웃을 수행하는 빈 페이지
export const LogoutPage = () => {
  let { next } = params();
  useEffect(() => {
    logout(next ? decodeURIComponent(next) : process.env.REACT_APP_SCC_URL);
  }, []);

  return (
    <Wrapper>
      <SpinnerContainer>
        <SpinnerSvg />
      </SpinnerContainer>
    </Wrapper>
  );
};

const spinnerAnimation = keyframes`
    0% {
        transform: rotate(0deg)
    }
    100% {
        transform: rotate(360deg)
    }
`;

const Wrapper = styled.div`
  width: 100vw;
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${spinnerAnimation} 1s linear infinite;
`;

const SpinnerSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M6.66707 19.9993C6.66707 12.6356 12.6366 6.66602 20.0004 6.66602C27.3642 6.66602 33.3337 12.6356 33.3337 19.9993C33.3337 27.3631 27.3642 33.3327 20.0004 33.3327"
      stroke="url(#paint0_linear_11087_99292)"
      strokeWidth="3.33344"
      strokeLinecap="round"
    />
    <path
      d="M20.0004 33.3327C27.3642 33.3327 33.3337 27.3631 33.3337 19.9993C33.3337 12.6356 27.3642 6.66602 20.0004 6.66602C12.6366 6.66602 6.66707 12.6356 6.66707 19.9993C6.66707 22.4279 7.31637 24.7049 8.45084 26.666"
      stroke="url(#paint1_linear_11087_99292)"
      strokeWidth="3.33344"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_11087_99292"
        x1="26.6671"
        y1="33.3327"
        x2="5.83374"
        y2="25.8327"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9DA7AE" />
        <stop offset="0.382331" stopColor="#9DA7AE" />
        <stop offset="0.818132" stopColor="#9DA7AE" stopOpacity="0.36" />
        <stop offset="1" stopColor="#9DA7AE" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_11087_99292"
        x1="10.0004"
        y1="-7.50065"
        x2="21.6671"
        y2="28.3327"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9DA7AE" />
        <stop offset="0.703125" stopColor="#9DA7AE" stopOpacity="0.208333" />
        <stop offset="0.848958" stopColor="#9DA7AE" stopOpacity="0" />
        <stop offset="1" stopColor="#9DA7AE" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);
